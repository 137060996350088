import React, { useEffect, useState } from 'react'
import ModalSummary from './components/ModalSummary'
import HotelItem from './components/HotelItem'
import SelectedHotel from './components/SelectedHotel'
import ModalHotelRooms from './components/ModalHotelRooms'
import FlightItem from './components/FlightItem'
import SelectedFlight from './components/SelectedFlight'
import ModalBaggage from './components/ModalBaggage'
import { FiSearch } from 'react-icons/fi'
import { useSearchParams } from 'react-router-dom'
import { getTicketsData } from '../../api/requests/oos'
import { useMutation } from 'react-query'
import { format } from 'date-fns'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Button,
    Center,
    Container, 
    Flex, 
    Icon, 
    Input, 
    InputGroup, 
    InputLeftElement, 
    Skeleton, 
    Slide, 
    SlideFade, 
    Stack, 
    Text,
    VStack,
    useMediaQuery
} from '@chakra-ui/react'

function Home() {
    const [isLargerThan500] = useMediaQuery('(min-width: 500px)')
    const [searchParams] = useSearchParams()

    const [search, setSearch] = useState('')
    const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

    const [selectedFlight, setSelectedFlight] = useState(null)
    const [selectedDepartureFlight, setSelectedDepartureFlight] = useState(null)
    const [selectedReturnFlight, setSelectedReturnFlight] = useState(null)
    const [selectedFlightBaggage, setSelectedFlightBaggage] = useState(null)
    const [selectedDepartureFlightBaggage, setSelectedDepartureFlightBaggage] = useState(null)
    const [selectedReturnFlightBaggage, setSelectedReturnFlightBaggage] = useState(null)

    const [modalBaggage, setModalBaggage] = useState(false)
    const [modalHotelRooms, setModalHotelRooms] = useState(false)
    const [modalSummary, setModalSummary] = useState(false)

    const [previewHotel, setPreviewHotel] = useState(null)
    const [selectedHotel, setSelectedHotel] = useState(null)

    const [isSuccess, setIsSuccess] = useState(false)

    const tickets = useMutation(getTicketsData)

    const isShowFlightTickets = () => {
        return !tickets?.isLoading && tickets?.data?.isFlightNotRoundTrip && !selectedFlight
    }

    const isShowDepartureFlightTickets = () => {
        return !tickets?.isLoading && tickets?.data?.isFlightRoundTrip && !selectedDepartureFlight
    }

    const isShowReturnFlightTickets = () => {
        return !tickets?.isLoading && tickets?.data?.isFlightRoundTrip && selectedDepartureFlight && !selectedReturnFlight
    }

    const isShowHotelTickets = () => {
        if (!tickets?.isLoading && tickets?.data?.isFlightRoundTrip) {
            return !tickets?.isLoading && tickets?.data?.isBookHotel && selectedDepartureFlight && selectedReturnFlight && selectedDepartureFlightBaggage && selectedReturnFlightBaggage && !selectedHotel
        } else if (!tickets?.isLoading && tickets?.data?.isFlightNotRoundTrip) {
            return !tickets?.isLoading && tickets?.data?.isBookHotel && selectedFlight && selectedFlightBaggage && !selectedHotel
        } else if (!tickets?.isLoading && !tickets?.data?.isFlightRoundTrip && !tickets?.data?.isFlightNotRoundTrip && tickets?.data?.isBookHotel && !selectedHotel) {
            return true
        } else {
            return false
        }
    }

    const isShowSlideSelectBaggage = () => {
        return  !tickets?.isLoading && tickets?.data?.isFlightRoundTrip && selectedDepartureFlight && selectedReturnFlight && !selectedDepartureFlightBaggage && !selectedReturnFlightBaggage ||
                !tickets?.isLoading && tickets?.data?.isFlightNotRoundTrip && selectedFlight && !selectedFlightBaggage
    }

    const isShowSlideSummary = () => {
        const isSelectedFlight = selectedFlight === null ? false : true
        const isSelectedDepartureFlight = selectedDepartureFlight === null ? false : true
        const isSelectedReturnFlight = selectedReturnFlight === null ? false : true
        const isSelectedFlightBaggage = selectedFlightBaggage === null ? false : true
        const isSelectedDepartureFlightBaggage = selectedDepartureFlightBaggage === null ? false : true
        const isSelectedReturnFlightBaggage = selectedReturnFlightBaggage === null ? false : true
        const isSelectedHotel = selectedHotel === null ? false : true

        const isOneWay = tickets?.data?.isFlightNotRoundTrip
        const isRoundTrip = tickets?.data?.isFlightRoundTrip
        const isHotel = tickets?.data?.isBookHotel

        const flightAndBaggage = isSelectedFlight && isSelectedFlightBaggage
        const departureFlightAndBaggage = isSelectedDepartureFlight && isSelectedDepartureFlightBaggage
        const returnFlightAndBaggage = isSelectedReturnFlight && isSelectedReturnFlightBaggage

        const isCompletedDeparture = departureFlightAndBaggage === isRoundTrip
        const isCompletedReturn = returnFlightAndBaggage === isRoundTrip

        const isCompletedOneWay = flightAndBaggage === isOneWay
        const isCompletedRoundTrip = isCompletedDeparture && isCompletedReturn
        const isCompletedHotel = isSelectedHotel === isHotel
        return isCompletedOneWay && isCompletedRoundTrip && isCompletedHotel
    }

    const handleShowModalBaggage = () => {
        if (tickets?.data?.isFlightRoundTrip) {
            setSelectedDepartureFlightBaggage(selectedDepartureFlight?.journeys[0]?.segments[0]?.addOns?.baggageOptions[0])
            setSelectedReturnFlightBaggage(selectedReturnFlight?.journeys[0]?.segments[0]?.addOns?.baggageOptions[0])
        }
        if (tickets?.data?.isFlightNotRoundTrip) {
            setSelectedFlightBaggage(selectedFlight?.journeys[0]?.segments[0]?.addOns?.baggageOptions[0])
        }
        setModalBaggage(true)
    }

    useEffect(() => {
        tickets.mutate(searchParams.get('id'))
    }, [])

    return (
        <Container 
            maxWidth='container.xl' 
            as={Flex} 
            gap='20px' 
            flexDirection='column'
            paddingY='20px'
        >
            {
                isLargerThan768 &&
                <Button 
                    width='max-content'
                    fontSize='sm'
                    onClick={() => {
                        const urlBack = window?.location?.search?.split("url_back=")[1]
                        // window.parent.location.href = searchParams?.get('url_back')
                        // console.log(encodeURIComponent(searchParams?.get('url_back')))
                        window.parent.location.href = urlBack
                    }}
                >
                    Back
                </Button>
            }
            <Flex flexDirection='column' textAlign='center'>
                {
                    tickets?.isLoading
                        ?   !isSuccess &&
                            <VStack>
                                <Skeleton 
                                    height='25px' 
                                    width='150px' 
                                    borderRadius='6px'
                                    margin='0px auto'
                                />
                                <Skeleton 
                                    height='25px' 
                                    width='150px' 
                                    borderRadius='6px'
                                    margin='0px auto'
                                />
                                <Skeleton 
                                    height='25px' 
                                    width='150px' 
                                    borderRadius='6px'
                                    margin='0px auto'
                                />
                            </VStack>
                        :   !isSuccess &&
                            <VStack>
                                <Text fontWeight='semibold'>
                                    {tickets?.data?.data?.trip?.ticket} 
                                    {tickets?.data?.isFlightRoundTrip 
                                        ?   ' (Round Trip)' 
                                        :   tickets?.data?.isFlightNotRoundTrip
                                            ?   ' (One Way)'
                                            :   '' 
                                    }
                                </Text>
                                {
                                    tickets?.data?.isFlightNotRoundTrip || tickets?.data?.isFlightRoundTrip 
                                        ?   <Stack>
                                                <Text fontWeight='semibold'>
                                                    {tickets?.data?.data?.trip?.flight_merge_from?.name} - {tickets?.data?.data?.trip?.flight_merge_to?.name}
                                                </Text>
                                                <Text fontWeight='semibold'>
                                                    {format(new Date(tickets?.data?.data?.trip?.departure_date), 'iiii, dd MMMM yyyy')}
                                                </Text>
                                            </Stack>
                                        :   null    
                                }
                                {
                                    tickets?.data?.isBookHotel
                                        ?   <Stack>
                                                <Text fontWeight='semibold'>
                                                    {tickets?.data?.data?.trip?.hotel_lokasi?.name}
                                                </Text>
                                                <Text fontWeight='semibold'>
                                                    {format(new Date(tickets?.data?.data?.trip?.hotel_check_in), 'iiii, dd MMMM yyyy')} - {format(new Date(tickets?.data?.data?.trip?.hotel_check_out), 'iiii, dd MMMM yyyy')}
                                                </Text>
                                            </Stack>
                                        :   null
                                }
                            </VStack>
                }
            </Flex>

            {!tickets?.isError && !isSuccess &&
                <InputGroup boxShadow='0px 0px 10px 1px #e8e8e8'>
                    <InputLeftElement
                        pointerEvents='none'
                        children={<FiSearch />}
                    />
                    <Input 
                        type='search' 
                        placeholder='search ...' 
                        disabled={tickets?.isLoading}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </InputGroup>
            }

            {tickets?.isError
                // ?   <Center>
                //         <Stack direction='row' alignItems='center'>
                //             <Icon as={AiOutlineInfoCircle} />
                //             <Text>{tickets?.error}</Text>
                //         </Stack>
                //     </Center>
                ?   !isSuccess &&
                    <Alert status='error'>
                        <AlertIcon />
                        <AlertTitle>{tickets?.error}</AlertTitle>
                    </Alert>
                :   !isSuccess &&
                    <>
                        <Flex gap={'10px'} direction={isLargerThan500 ? 'row' : 'column'}>
                        { 
                            selectedFlight
                                ?    <SelectedFlight 
                                        type='departure'
                                        data={selectedFlight} 
                                        onDelete={() => {
                                            setSearch('')
                                            setSelectedFlight(null)
                                            setSelectedFlightBaggage(null)
                                        }} 
                                    />
                                :   null
                        }
                        { 
                            selectedDepartureFlight
                                ?    <SelectedFlight 
                                        type='departure'
                                        data={selectedDepartureFlight} 
                                        onDelete={() => {
                                            setSearch('')
                                            setSelectedDepartureFlight(null)
                                            setSelectedDepartureFlightBaggage(null)
                                        }} 
                                    />
                                :   null
                        }
                        { 
                            selectedReturnFlight
                                ?    <SelectedFlight 
                                        type='return'
                                        data={selectedReturnFlight} 
                                        onDelete={() => {
                                            setSearch('')
                                            setSelectedReturnFlight(null)
                                            setSelectedReturnFlightBaggage(null)
                                        }} 
                                    />
                                :   null
                        }
                        { 
                            selectedHotel
                                ?   <SelectedHotel
                                        hotel={selectedHotel}
                                        onDelete={() => setSelectedHotel(null)}
                                    />
                                :   null
                        }
                    </Flex>

                    {
                        tickets?.isLoading
                            ?   <VStack>
                                    <Skeleton 
                                        height='212px' 
                                        width='100%'
                                        borderRadius='6px'
                                    />
                                </VStack>
                            :   null
                    }

                    {
                        isShowFlightTickets()
                            ?   tickets?.data?.data?.flights?.map((flight, index) => {
                                    if (!search) {
                                        return (
                                            <FlightItem
                                                key={index}
                                                data={flight}
                                                onClick={() => {
                                                    setSelectedFlight(flight)
                                                    setSearch('')
                                                }}
                                            />
                                        )
                                    }
                                    if (search && (flight?.airlineDetail[0]?.airlineName.toLowerCase()).includes(search.toLowerCase())) {
                                        return (
                                            <FlightItem
                                                key={index}
                                                data={flight}
                                                onClick={() => {
                                                    setSelectedFlight(flight)
                                                    setSearch('')
                                                }}
                                            />
                                        )
                                    }
                                })
                            :   null
                    }

                    {
                        isShowDepartureFlightTickets()
                            ?   tickets?.data?.data?.flightsRoundTrip?.departure?.map((flight, index) => {
                                    if (!search) {
                                        return (
                                            <FlightItem
                                                key={index}
                                                data={flight}
                                                onClick={() => {
                                                    setSelectedDepartureFlight(flight)
                                                    setSearch('')
                                                }}
                                            />
                                        )
                                    }
                                    if (search && (flight?.airlineDetail[0]?.airlineName.toLowerCase()).includes(search.toLowerCase())) {
                                        return (
                                            <FlightItem
                                                key={index}
                                                data={flight}
                                                onClick={() => {
                                                    setSelectedDepartureFlight(flight)
                                                    setSearch('')
                                                }}
                                            />
                                        )
                                    }
                                })
                            :   null
                    }

                    {
                        isShowReturnFlightTickets()
                            ?   tickets?.data?.data?.flightsRoundTrip?.return?.map((flight, index) => {
                                    if (!search) {
                                        return (
                                            <FlightItem
                                                key={index}
                                                data={flight}
                                                onClick={() => {
                                                    setSelectedReturnFlight(flight)
                                                    setSearch('')
                                                }}
                                            />
                                        )
                                    }
                                    if (search && (flight?.airlineDetail[0]?.airlineName.toLowerCase()).includes(search.toLowerCase())) {
                                        return (
                                            <FlightItem
                                                key={index}
                                                data={flight}
                                                onClick={() => {
                                                    setSelectedReturnFlight(flight)
                                                    setSearch('')
                                                }}
                                            />
                                        )
                                    }
                                })
                            :   null
                    }

                    {
                        isShowHotelTickets()
                            ?   tickets?.data?.data?.hotels?.properties?.map((hotel, index) => {
                                    if (!search) {
                                        return (
                                            <HotelItem
                                                key={index}
                                                hotel={hotel}
                                                onChoose={() => {
                                                    setPreviewHotel(hotel)
                                                    setModalHotelRooms(true)
                                                }}
                                            />
                                        )
                                    }
                                    if (search && (hotel?.propertySummary?.name?.toLowerCase()).includes(search.toLowerCase())) {
                                        return (
                                            <HotelItem
                                                key={index}
                                                hotel={hotel}
                                                onChoose={() => {
                                                    setPreviewHotel(hotel)
                                                    setModalHotelRooms(true)
                                                }}
                                            />
                                        )
                                    }
                                })
                            :   null
                    }
                    </>
            }

            {
                isSuccess &&
                <Alert status='success'>
                    <AlertIcon />
                    <AlertTitle>Success Order, Please back to your Travel Request</AlertTitle>
                </Alert>
            }

            {
                isShowSlideSelectBaggage() && !isSuccess &&
                <SlideFade
                    in={isShowSlideSelectBaggage()}
                    // direction='bottom'
                    // style={{ zIndex: 10 }}
                >
                    <Flex
                        w='100%'
                        h='70px'
                        boxShadow='0px 0px 10px 1px #e8e8e8'
                        backgroundColor='white'
                        alignItems='center'
                    >
                        <Container maxWidth='container.xl' as={Flex}>
                            <Button 
                                backgroundColor='blue.500' 
                                color='white' 
                                marginLeft='auto'
                                onClick={handleShowModalBaggage}
                            >
                                Select Baggage
                            </Button>
                        </Container>
                    </Flex>
                </SlideFade>
            }

            {
                isShowSlideSummary() && !isSuccess &&
                <SlideFade
                    in={isShowSlideSummary()}
                    // direction='bottom'
                    // style={{ zIndex: 10 }}
                >
                    <Flex
                        w='100%'
                        h='70px'
                        boxShadow='0px 0px 10px 1px #e8e8e8'
                        backgroundColor='white'
                        alignItems='center'
                    >
                        <Container maxWidth='container.xl' as={Flex}>
                            <Button 
                                backgroundColor='blue.500' 
                                color='white' 
                                marginLeft='auto'
                                onClick={() => setModalSummary(true)}
                            >
                                Continue
                            </Button>
                        </Container>
                    </Flex>
                </SlideFade>
            }

            {modalBaggage && !isSuccess &&
                <ModalBaggage 
                    type={tickets?.data?.isFlightRoundTrip ? 'round-trip' : 'not-round-trip'}
                    isOpen={modalBaggage} 
                    onClose={() => setModalBaggage(false)}
                    onContinue={() => setModalBaggage(false)}
                    tickets={tickets}
                    selectedFlight={selectedFlight}
                    selectedFlightBaggage={selectedFlightBaggage}
                    setSelectedFlightBaggage={setSelectedFlightBaggage}
                    selectedDepartureFlight={selectedDepartureFlight}
                    selectedDepartureFlightBaggage={selectedDepartureFlightBaggage}
                    setSelectedDepartureFlightBaggage={setSelectedDepartureFlightBaggage}
                    selectedReturnFlight={selectedReturnFlight}
                    selectedReturnFlightBaggage={selectedReturnFlightBaggage}
                    setSelectedReturnFlightBaggage={setSelectedReturnFlightBaggage}
                />
            }

            {modalHotelRooms && !isSuccess &&
                <ModalHotelRooms
                    isOpen={modalHotelRooms}
                    onClose={() => setModalHotelRooms(false)}
                    previewHotel={previewHotel}
                    trip={tickets?.data?.data?.trip}
                    setSelectedHotel={setSelectedHotel}
                />
            }

            {modalSummary && !isSuccess &&
                <ModalSummary
                    selectedFlight={selectedFlight}
                    selectedDepartureFlight={selectedDepartureFlight}
                    selectedReturnFlight={selectedReturnFlight}
                    selectedFlightBaggage={selectedFlightBaggage}
                    selectedDepartureFlightBaggage={selectedDepartureFlightBaggage}
                    selectedReturnFlightBaggage={selectedReturnFlightBaggage}
                    selectedHotel={selectedHotel}
                    trip={tickets?.data?.data?.trip}
                    tickets={tickets?.data}
                    isOpen={modalSummary}
                    onClose={() => setModalSummary(false)}
                    onSuccess={() => {
                        setModalSummary()
                        setIsSuccess(true)
                    }}
                />
            }
        </Container>
    )
}

export default Home